// Components
import Image from 'next/image';
import Link from 'next/link';

interface IProps {
  icons: Icon[];
  iconHeight: string;
  iconWidth: string;
}

interface Icon {
  url: string;
  link: string;
}

const IconGrid: React.FC<IProps> = ({ icons, iconHeight, iconWidth }): React.ReactElement => {
  const imageLoader = ({ src }): string => {
    return `${src}`;
  };
  return (
    <div className="icon-grid flex flex-wrap justify-center items-center py-10">
      {icons.map((icon, index) => (
        <div
          className={`icon relative ${iconHeight} ${iconWidth} mx-3 my-3`}
          key={`${icon}-${index}`}
        >
          <Link href={icon.link}>
            <a>
              <Image
                layout="fill"
                loader={imageLoader}
                src={icon.url}
                objectFit="contain"
                alt="icon"
              />
            </a>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default IconGrid;
