import Company from 'types/company';
import PageWrapper from '@components/pageWrapper';
import campaign from 'types/campaign';
import Skeleton from 'react-loading-skeleton';
import Link from 'next/link';

interface campaignProps {
  companyID: Company;
  campaignBanner: campaign[] | [];
}

function Campaign({ companyID, campaignBanner }: campaignProps): JSX.Element {
  const htmlSerializer = (
    content: {
      type: string;
      text: string;
      spans: {
        start: number;
        end: number;
        type: string;
      }[];
    },
    index: number
  ): JSX.Element => {
    switch (content.type) {
      case 'heading1':
        return (
          <h1
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'heading2':
        return (
          <h2
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'heading3':
        return (
          <h3
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'heading4':
        return (
          <h4
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'heading5':
        return (
          <h5
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'heading6':
        return (
          <h6
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'paragraph':
        return (
          <p
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'preformatted':
        return (
          <pre
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'strong':
        return (
          <strong
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'em':
        return (
          <em
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      case 'label': {
        return (
          <span
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
      }
      case 'span':
        return content ? (
          <br key={`prismic-props-${index}`} />
        ) : (
          <span key={`prismic-props-${index}`} />
        );
      default:
        return (
          <div
            key={`prismic-props-${index}`}
            dangerouslySetInnerHTML={{ __html: convertWithSpan(content.spans, content.text) }}
          />
        );
    }
  };

  const convertWithSpan = (
    toConvert: {
      start: number;
      end: number;
      type: string;
    }[],
    text: string | undefined
  ): string => {
    const span = toConvert;
    if (text) {
      return span
        .reduce(function (
          r,
          a: {
            start: number;
            end: number;
            type: string;
            data?: { link_type: string; url: 'string' };
          }
        ) {
          r[a.start] =
            `<${a.type === 'hyperlink' ? `a href=${a.data?.url} class="underline"` : a.type}>` +
            r[a.start];
          r[a.end - 1] += `</${a.type === 'hyperlink' ? 'a' : a.type}>`;
          return r;
        }, text.split(''))
        .join('');
    } else {
      return '';
    }
  };

  return (
    <section className="home-banner">
      {campaignBanner.length > 0 ? (
        <PageWrapper>
          <div className="home-banner__inner">
            <div className="home-banner__content">
              {campaignBanner[0].data.headline.map((headline, index) =>
                htmlSerializer(headline, index)
              )}
              {campaignBanner[0].data.sub_headline.map((sub_headline, index) =>
                htmlSerializer(sub_headline, index)
              )}
              {campaignBanner[0].data.body.map((body, index) => htmlSerializer(body, index))}
              <div className="hero__actions">
                <Link href={campaignBanner[0].data.button_link.url}>
                  <a className={`cta-btn${companyID === Company.Ironmongery ? '--id' : '--ed'}`}>
                    <span className="text-center block">{campaignBanner[0].data.button_label}</span>
                  </a>
                </Link>
              </div>
            </div>
            <div
              className="home-banner__image"
              style={{
                backgroundImage: `url(${campaignBanner[0].data.campaign_background_image.url})`,
              }}
            ></div>
          </div>
        </PageWrapper>
      ) : (
        <Skeleton className="banner--loading" />
      )}
    </section>
  );
}

export default Campaign;
