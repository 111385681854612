import Link from 'next/link';
// import { useFlags } from 'launchdarkly-react-client-sdk';

import Company from 'types/company';
import { GTagCategory } from 'types/googleAnalytics';

interface pageProps {
  companyID: Company;
}

const HomeReasons = ({ companyID }: pageProps): JSX.Element => {
  // const flags: {
  //   thresholdVariation: {
  //     price: string;
  //     threshold: string;
  //   };
  // } = useFlags();
  // const { thresholdVariation } = flags;

  const idReasons = [
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/f_auto,q_auto/t_400-scale/v1632228162/ironmongery/reasons-to-shop/ID/biggest-range.svg',
      heading: 'Over 18,000 products in stock',
      benefits: [
        'Over 18,000 products in stock for next day delivery',
        'Top trade brands in stock',
        "Can't find it on our website? Give us a call and we'll find it for you",
      ],
      text: '18,000 products in stock for next day delivery, including top trade brands',
      ctaLink: '/browse/all',
    },
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/f_auto,q_auto/t_400-scale/v1632229005/ironmongery/reasons-to-shop/ID/free-returns.svg',
      heading: 'Free Returns',
      benefits: [
        'Changed your mind? Send it back and we’ll refund you right away - no questions asked. That’s our promise to you.',
        'Return for free via Royal Mail, CollectPlus, or we can even collect it from you',
      ],
      text: 'Return for free via Royal Mail, CollectPlus, or we can even collect it from you',
      ctaLink: '/information/returns',
    },
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/f_auto,q_auto/t_400-scale/v1632228009/ironmongery/reasons-to-shop/ID/free-delivery.svg',
      heading: 'Free delivery available',
      benefits: [
        'Order by 9pm for next day delivery - and it’s free if your order is over £45!',
        'Order by 4pm on Saturday - for delivery on Sunday',
        'Upgrade for delivery before 10:30am or 12pm',
      ],
      text: 'Order by 9pm for next day delivery - and it’s free if your order is over £45!',
      ctaLink: '/information/delivery',
    },
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/f_auto,q_auto/t_400-scale/v1632229200/ironmongery/reasons-to-shop/ID/five-year-guarantee.svg',
      heading: 'Five Year Minimum Guarantee',
      benefits: [
        'All our ironmongery has a minimum 5-year guarantee',
        'If you have a problem, we’ll replace it at no cost to you',
        'We have a rigorous quality control process & publish all product reviews from our customers',
      ],
      text: 'If you have a problem, we’ll replace it at no cost to you',
      ctaLink: '/reasons-to-shop#guarantee',
    },
  ];

  const edReasons = [
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/v1676986166/ironmongery/reasons-to-shop/ED/biggest-range_-updated.svg',
      heading: 'Huge Range in Stock',
      benefits: [
        'Huge electrical range in stock for next day delivery',
        'Top trade brands in stock',
        "Can't find it on our website? Give us a call and we'll find it for you",
      ],
      text: 'Huge electrical range in stock for next day delivery',
      ctaLink: '/browse/all',
    },
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/v1676986166/ironmongery/reasons-to-shop/ED/free-returns-_updated.svg',
      heading: 'Free Returns',
      benefits: [
        'Changed your mind? Send it back and we’ll refund you right away - no questions asked. That’s our promise to you.',
        'Return for free via Royal Mail, CollectPlus, or we can even collect it from you',
      ],
      text: 'Return for free via Royal Mail, CollectPlus, or we can even collect it from you',
      ctaLink: '/information/returns',
    },
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/v1676986166/ironmongery/reasons-to-shop/ED/free-delivery-updated.svg',
      heading: 'Free delivery available',
      benefits: [
        'Order by 9pm for next day delivery - and it’s free if your order is over £45!',
        'Order by 4pm on Saturday - for delivery on Sunday',
        'Upgrade for delivery before 10:30am or 12pm',
      ],
      text: 'Order by 9pm for next day delivery - and it’s free if your order is over £45!',
      ctaLink: '/information/delivery',
    },
    {
      image:
        'https://res.cloudinary.com/manutantraders/image/upload/v1676986166/ironmongery/reasons-to-shop/ED/award-winning-service-updated.svg',
      heading: 'Award-Winning Service',
      benefits: [
        'Experience servicing the trade for almost 30 years',
        'Call our expert & agents 7 days a week from 7am-8pm',
        'We have over 7,500 independent reviews - rating us excellent!',
      ],
      text: 'Call our expert & agents 7 days a week from 7am-8pm',
      ctaLink: '/contact',
    },
  ];

  // const economyList = [
  //   'Order by 9pm for next day delivery - and it’s free if your order is over £45!',
  //   'Order by 4pm on Saturday - for delivery on Sunday',
  //   'Upgrade for delivery before 10:30am or 12pm',
  // ];

  return (
    <section className="reasons-to-shop__section">
      <div className="root-container">
        <h2 className="reasons-to-shop__section-heading">Reasons to shop with us</h2>
        <div className="reasons-to-shop__reasons">
          {(companyID === Company.Ironmongery ? idReasons : edReasons).map(
            ({ image, heading, benefits, text, ctaLink }, index) => (
              <div key={index} className="reasons-to-shop__reason">
                <img className="reasons-to-shop__reason-img pt-5" src={image} alt={heading} />
                <h3 className="reasons-to-shop__reason-heading">{heading}</h3>
                <ul className="reasons-to-shop__reason-list">
                  {/* {heading === 'Free delivery available' ? (
                    <>
                      {economyList.map((benefit, index) => (
                        <li key={index}>
                          <svg
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.20699 8.51786L0.732121 6.04299C0.439228 5.7501 0.439228 5.27522 0.732121 4.98233C1.02501 4.68944 1.49989 4.68944 1.79278 4.98233L3.74945 6.93902L9.94964 0.739689C10.2425 0.446796 10.7174 0.446796 11.0103 0.739689C11.3032 1.03258 11.3032 1.50746 11.0103 1.80035L4.29278 8.51786C4.15965 8.651 3.98892 8.72361 3.81476 8.73572H3.68501C3.51086 8.72361 3.34013 8.651 3.20699 8.51786Z"
                              fill={`${companyID === Company.Ironmongery ? '#EB9218' : '#AFBA03'}`}
                            />
                          </svg>
                          <p>
                            {index === 0
                              ? +thresholdVariation?.threshold === 75
                                ? 'Order by 9pm for next day delivery - and it’s free if your order is over £75!'
                                : +thresholdVariation?.threshold === 60
                                ? 'Order by 9pm for next day delivery - and it’s free if your order is over £60!'
                                : benefit
                              : benefit}
                          </p>
                        </li>
                      ))}
                    </>
                  ) : ( */}
                  <>
                    {benefits.map((benefit, index) => (
                      <li key={index}>
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.20699 8.51786L0.732121 6.04299C0.439228 5.7501 0.439228 5.27522 0.732121 4.98233C1.02501 4.68944 1.49989 4.68944 1.79278 4.98233L3.74945 6.93902L9.94964 0.739689C10.2425 0.446796 10.7174 0.446796 11.0103 0.739689C11.3032 1.03258 11.3032 1.50746 11.0103 1.80035L4.29278 8.51786C4.15965 8.651 3.98892 8.72361 3.81476 8.73572H3.68501C3.51086 8.72361 3.34013 8.651 3.20699 8.51786Z"
                            fill={`${companyID === Company.Ironmongery ? '#EB9218' : '#AFBA03'}`}
                          />
                        </svg>
                        <p>{benefit}</p>
                      </li>
                    ))}
                  </>
                  {/* )} */}
                </ul>
                <p className="reasons-to-shop__reason-text">{text}</p>
                <Link href={ctaLink}>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga('send', 'event', GTagCategory.Homepage, 'Reasons To Shop', heading);
                    }}
                    className={`reasons-to-shop__reason-cta ${
                      companyID === Company.Ironmongery ? 'cta-btn--id' : 'cta-btn--ed'
                    }`}
                  >
                    More Info
                  </a>
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeReasons;
