import PageWrapper from '@components/pageWrapper';
import Company from 'types/company';
import Carousel from '@components/Carousel';
import { useMediaQuery } from 'react-responsive';

interface pageProps {
  companyID: Company;
  companyFind: string[];
}

function CompanyFind({ companyID, companyFind }: pageProps): JSX.Element {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <section className="home-find">
      <PageWrapper>
        <div className="home-find__inner">
          <Carousel
            companyID={companyID}
            direction={isMobile ? 'y' : 'x'}
            content={companyFind.map((item, index) => (
              <div
                className="carousel__slide"
                aria-hidden="false"
                key={`home-find-${index}`}
                dangerouslySetInnerHTML={{
                  __html: item.replace('cta-btn--white', 'cta-btn--plain--white'),
                }}
              />
            ))}
          />
        </div>
      </PageWrapper>
    </section>
  );
}

export default CompanyFind;
