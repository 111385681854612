import PageWrapper from '@components/pageWrapper';
import Link from 'next/link';
import Company from 'types/company';

interface pageProps {
  companyID: Company;
}

function Callout({ companyID }: pageProps): JSX.Element {
  return (
    <section className="home-callout">
      <PageWrapper>
        <>
          <div
            className="home-callout__background hidden sm:block"
            data-bg="https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco/campaigns/commerical/image-9_2x.png"
            style={{
              backgroundImage: `url("https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco/campaigns/commerical/image-9_2x.png")`,
            }}
          />
          <div
            className="home-callout__background block sm:hidden"
            data-bg="https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco/campaigns/commerical/image-9-mobile.png"
            style={{
              backgroundImage: `url("https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco/campaigns/commerical/image-9-mobile.png")`,
            }}
          />
          <div className="home-callout__inner">
            <div className="home-callout__content">
              <div className="home-callout__content-inner">
                <p>
                  {companyID === Company.Ironmongery &&
                    'Get in touch with our commercial accounts team for more information.'}
                </p>
                {companyID === Company.Ironmongery ? (
                  <h2>
                    Large Projects. <span>Made Simple.</span>
                  </h2>
                ) : (
                  <h2>
                    Large <span>Commercial Projects.</span>
                  </h2>
                )}
                <p>
                  {companyID === Company.Electrical &&
                    'Speak to our Commercial Account Management team today for product sourcing special delivery requests and more.'}
                </p>
              </div>
              <div className="home-callout__actions">
                <Link href="/commercial?promo_name=commercial_mar21&amp;promo_position=homepg_secondary_1&amp;situation=WebSecondary">
                  <a className="cta-btn cta-btn--plain--white">
                    <span className="text-center block">Find Out More</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </>
      </PageWrapper>
    </section>
  );
}

export default Callout;
