import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
// import { selectDeliveryOption } from 'src/redux/checkoutSlice';
import Company from 'types/company';
import Campaign from '@components/Campaign';
import Discover from '@components/Discover';
import HomeBrands from '@components/HomeBrands';
import CompanyFind from '@components/CompanyFind';
import Callout from '@components/Callout';

import { useEffect, useState } from 'react';
import blogs from 'types/blog';
import axios from 'axios';
import campaign from 'types/campaign';
import Head from 'next/head';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import SearchBarHero from '@components/SearchBarHero';
import CROTestimonials from '@components/Testimonials/CROTestimonials';
import HomeReasons from '@components/HomeReasons';
import IconGrid from '@components/Global/IconGrid';
import Button, { Variant, Size } from '@components/button';
import PageWrapper from '@components/pageWrapper';

import Link from 'next/link';

import { setDYContext } from '@utils/setDYContext';
import { DYContext } from 'types/dynamicYield';
import { setIsDYContextSet } from 'src/redux/dyContextSlice';
import { useAppDispatch } from '@hooks/redux';
import { TrustPilotOverview, TrustPilotReview } from 'types/trustpilot';

// Dynamic Imports
const HomeBlog = dynamic(() => import('@components/HomeBlog'));

interface pageProps {
  error: boolean;
  campaignBanner: campaign[] | [];
  companyFind: string[];
  isJestTesting?: boolean;
  homeBlogs: blogs['blogContents'];
  trustPilotOverview: TrustPilotOverview;
  trustPilotReviews: TrustPilotReview[];
}

declare const dataLayer: any;

const brandIcons = [
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1624618966/electrical/brands/product-page/Zink.jpg',
    link: 'brands/zink',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1607693205/electrical/brands/product-page/Knightsbridge.jpg',
    link: 'brands/knightsbridge',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1662376065/electrical/brands/homepage/wylex.jpg',
    link: 'brands/wylex',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1661850527/electrical/brands/product-page/IntegralLED.png',
    link: 'brands/integral',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1655713332/electrical/brands/product-page/Crabtree.jpg',
    link: 'brands/crabtree',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1662376064/electrical/brands/homepage/heatmat.png',
    link: 'brands/heatmat',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1661850521/electrical/brands/product-page/Appleby1.png',
    link: 'brands/appleby',
  },
  {
    url: 'https://img-cdn.manutantraders.com/image/upload/v1662376064/electrical/brands/homepage/mk.png',
    link: 'brands/mk',
  },
];

const Home = ({
  campaignBanner,
  companyFind,
  isJestTesting,
  trustPilotOverview,
  trustPilotReviews,
}: // homeBlogs,
pageProps): React.ReactElement => {
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);

  const dispatch = useAppDispatch();

  const [header, setHeader] = useState({
    header1: false,
    header3: false,
    header4: false,
    header5: false,
    header6: false,
    header7: false,
  });

  useEffect(() => {
    if (isDocumentReady && !isJestTesting) {
      dataLayer.push({
        ecomm_pagetype: 'homepage',
      });
    }
  }, [isJestTesting, isDocumentReady]);

  useEffect(() => {
    setDYContext(DYContext.Homepage);
    dispatch(setIsDYContextSet(true));
    setIsDocumentReady(true);
  }, []);

  return (
    <>
      {isDocumentReady && (
        <Head>
          <meta name="robots" content="index,follow" />
          <link
            rel="canonical"
            href={
              companyID === Company.Ironmongery
                ? 'https://www.ironmongerydirect.co.uk'
                : 'https://www.electricaldirect.co.uk'
            }
          />
          <script type="application/ld+json">
            {`
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "${
              companyID === Company.Ironmongery
                ? 'https://www.ironmongerydirect.co.uk/'
                : 'https:www.electricaldirect.co.uk'
            }",
            "potentialAction": {
            "@type": "SearchAction",
            "target": "https://${
              companyID === Company.Ironmongery
                ? 'www.ironmongerydirect.co.uk'
                : 'www.electricaldirect.co.uk'
            }/search?query={search_term_string}",
            "query-input": "required name=search_term_string"
            }
            `}
          </script>
          <script type="application/ld+json">
            {`
            "@context": "https://schema.org",
            "@type": "HardwareStore",
            "name": "${
              companyID === Company.Ironmongery ? 'IronmongeryDirect' : 'ElectricalDirect'
            }",
            "image": "https://img-cdn.manutantraders.com/image/upload/shared/other/companyPremises.jpg",
            "@id": "",
            "url": "https://${
              companyID === Company.Ironmongery
                ? 'www.ironmongerydirect.co.uk'
                : 'www.electricaldirect.co.uk'
            }/",
            "telephone": ${companyID === Company.Ironmongery ? '03003 038 821' : '03003 038 826'},
            "address": {
            "@@type": "PostalAddress",
            "streetAddress": "Scimitar Park, Courtauld Road",
            "addressLocality": "Basildon",
            "postalCode": "SS131ND",
            "addressCountry": "GB"
            } ,
            "sameAs": [
            "https://www.facebook.com/${
              companyID === Company.Ironmongery ? 'IronmongeryDirect' : 'ElectricalDct'
            }",
            "https://twitter.com/${
              companyID === Company.Ironmongery ? 'IronmngryDirect' : 'ElectricalDct'
            }",
            "https://www.linkedin.com/company/${
              companyID === Company.Ironmongery ? 'ironmongery-direct-ltd' : 'electrical-direct'
            }/"
            ],
            "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
            ],
            "opens": "07:00",
            "closes": "20:00"
            }
            `}
          </script>
          <script type="application/ld+json">
            {`
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "${
              companyID === Company.Ironmongery ? 'IronmongeryDirect' : 'ElectricalDirect'
            } Limited",
            "alternateName": ${
              companyID === Company.Ironmongery ? 'Ironmongery Direct' : 'Electrical Direct'
            },
            "url": "https://www.${
              companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
            }direct.co.uk/",
            "logo": ${
              companyID === Company.Ironmongery
                ? 'https://www.ironmongerydirect.co.uk/assets/images/ID_RGB_POSITIVE.jpg'
                : 'https://www.electricaldirect.co.uk/assets/images/ED_RGB_POSITIVE.jpg'
            },
            "sameAs": [
            "https://www.facebook.com/${
              companyID === Company.Ironmongery ? 'IronmongeryDirect' : 'ElectricalDct'
            }",
            "https://twitter.com/${
              companyID === Company.Ironmongery ? 'IronmngryDirect' : 'ElectricalDct'
            }",
            "https://www.linkedin.com/company/${
              companyID === Company.Ironmongery ? 'ironmongery-direct-ltd' : 'electrical-direct'
            }/"
            ],
            "parentOrganization": {
            "@type": "Organization",
            "@id": "https://www.manutan.com/en/homepage/our-vision/our-mission",
            "name": "Manutan",
            "description": "The Manutan group specialises in the distribution of equipment and supplies for companies and local authorities.",
            "logo": [
            "https://www.manutan.com/medias/img/header_logo_en.png"
            ],
            "url": "https://www.manutan.com/en/homepage/our-vision/our-mission"
            }
            `}
          </script>
          <meta
            name="description"
            content={
              companyID === Company.Ironmongery
                ? "Buy Door Furniture, Hinges and more from the UK's Largest Ironmongery Range. Available with Same Day Despatch & Free Returns as Standard, all great Trade Prices."
                : 'Huge range of electrical products at trade prices. Shop Sockets & Switches, Cables, Lighting and many more, with free next day delivery and returns.'
            }
          />
          {companyID === Company.Electrical && (
            <meta name="facebook-domain-verification" content="j8qixiq3631c1w78scqvezxlda7dzj" />
          )}
          <title>
            {companyID === Company.Ironmongery
              ? 'IronmongeryDirect | UK’s Biggest Range | Same Day Despatch'
              : 'ElectricalDirect | Your Online Electrical Wholesaler'}
          </title>
          <link
            rel="icon"
            href={`${
              companyID === Company.Ironmongery
                ? '/favicons/favicon-id.ico'
                : '/favicons/favicon-ed.ico'
            }`}
          />
        </Head>
      )}
      <main className={companyID === Company.Ironmongery ? 'theme-id' : 'theme-ed'}>
        {companyID === Company.Ironmongery && (
          <div className="block md:hidden">
            <SearchBarHero
              companyID={companyID}
              trustPilotOverview={trustPilotOverview}
              trustPilotReviews={trustPilotReviews}
            />
          </div>
        )}
        {companyID === Company.Ironmongery && (
          <div className="hidden md:block">
            <Campaign companyID={companyID} campaignBanner={campaignBanner} />
          </div>
        )}

        {companyID === Company.Ironmongery && (
          <>
            <div className="block md:hidden">
              <HomeReasons companyID={companyID} />
              <CROTestimonials
                companyID={companyID}
                trustPilotOverview={trustPilotOverview}
                trustPilotReviews={trustPilotReviews}
              />
              <Discover companyID={companyID} />
            </div>
            <div className="hidden md:block">
              <HomeReasons companyID={companyID} />
              <CROTestimonials
                companyID={companyID}
                trustPilotOverview={trustPilotOverview}
                trustPilotReviews={trustPilotReviews}
              />
              {companyID !== Company.Ironmongery && (
                <>
                  {/* <ImageHeader /> */}
                  <div className="flex flex-col justify-center items-center">
                    <IconGrid
                      icons={brandIcons}
                      iconHeight="h-20 sm:h-24 xl:h-40"
                      iconWidth="w-20 sm:w-24 xl:w-40"
                    />
                    <div className="inline-block mb-10">
                      <Button variant={Variant.GREEN} size={Size.SMALL}>
                        <Link href="/brands">
                          <a>View All The Brands</a>
                        </Link>
                      </Button>
                    </div>
                  </div>
                </>
              )}
              <Discover companyID={companyID} />
              <PageWrapper>
                {/* Dynamic Yield Identifier */}
                {companyID === Company.Ironmongery && <div id="dy_homepage_recs_0"></div>}
              </PageWrapper>
              <HomeBrands companyID={companyID} />
              <CompanyFind companyID={companyID} companyFind={companyFind} />
              <Callout companyID={companyID} />
              <HomeBlog companyID={companyID} />
            </div>
          </>
        )}
        {companyID === Company.Electrical && (
          <div className="bg-white py-4 px-5 lg:px-12 flex flex-col items-center">
            <>
              <div className="py-8 max-w-768px">
                <p className="text-xl md:text-2xl leading-7 mb-6">
                  To continue to provide you with the very best service, we’ve made the decision to
                  stop taking orders on the ElectricalDirect website from 1st September and close
                  the business shortly after.
                </p>
                <p className="text-xl md:text-2xl leading-7 mb-6">
                  We’ll be moving many of our most popular products to our sister company,{' '}
                  <a
                    className="text-blue-link font-bold underline"
                    href="https://www.ironmongerydirect.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IronmongeryDirect
                  </a>
                  , who will honour all existing orders, returns, and guarantees. They have over
                  70,000 5-star reviews and have been trading for over 50 years – so you’ll be in
                  very capable hands!
                </p>
                <p className="text-xl md:text-2xl leading-7">
                  As we make this change, we understand that you may have questions and concerns.
                  We&apos;re here to help and ensure a smooth process for all our valued customers.
                  Below, you&apos;ll find answers to some of the most asked questions about the
                  closure of our website and the transition to our sister company. We&apos;re
                  committed to maintaining our promise of quality and trust, and we thank you for
                  your continued support.
                </p>
              </div>
              <h1 className="text-xl md:text-4xl font-bold leading-tight py-4">
                Frequently asked questions
              </h1>
              <div className="flex flex-col md:flex-row md:space-x-10">
                <div className="w-full max-w-768px">
                  <div className="accordion w-full mx-auto" id="accordionReturns">
                    <div
                      className={`accordion-card ${
                        header.header1 ? 'border-2 border-gray-accordion rounded-t-3xl' : ''
                      }`}
                    >
                      <div
                        className={`accordion-card-header ${header.header1 ? 'rounded-t-2xl' : ''}`}
                        id="headingTwo"
                      >
                        <h2>
                          <button
                            className="btn btn-link collapsed text-xl leading-snug h-auto p-4 pr-10 min-h-60px"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            onClick={() =>
                              setHeader({
                                header1: !header.header1,
                                header3: false,
                                header4: false,
                                header5: false,
                                header6: false,
                                header7: false,
                              })
                            }
                          >
                            How do I return my order?
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className={header.header1 ? 'collapsed' : ''}
                            >
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className={`collapse ${header.header1 ? 'show' : ''}`}
                        aria-labelledby="headingTwo"
                        data-parent="#accordionReturns"
                      >
                        <div className="accordion-card-body">
                          <p className="text-xl md:text-2xl leading-7">
                            If you ordered within the last 28 days, you can use our{' '}
                            <Link href="https://returns.manutantraders.co.uk/">
                              <a target="_blank" rel="noopener noreferrer">
                                returns portal
                              </a>
                            </Link>{' '}
                            as usual to create a return and print a label.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`accordion-card ${
                        header.header3 ? 'border-2 border-gray-accordion rounded-t-3xl' : ''
                      }`}
                    >
                      <div
                        className={`accordion-card-header ${header.header3 ? 'rounded-t-2xl' : ''}`}
                        id="headingThree"
                      >
                        <h2>
                          <button
                            className="btn btn-link collapsed text-xl leading-snug h-auto p-4 pr-10 min-h-60px"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() =>
                              setHeader({
                                header1: false,
                                header3: !header.header3,
                                header4: false,
                                header5: false,
                                header6: false,
                                header7: false,
                              })
                            }
                          >
                            How do I claim under the 1-year guarantee / product warranty?{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className={header.header3 ? 'collapsed' : ''}
                            >
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className={`collapse ${header.header3 ? 'show' : ''}`}
                        aria-labelledby="headingThree"
                        data-parent="#accordionReturns"
                      >
                        <div className="accordion-card-body">
                          <p className="text-xl md:text-2xl leading-7">
                            If a product has developed a fault or quality issue, then please contact
                            us by email at{' '}
                            <a href="mailto:sales@ironmongerydirect.com">
                              sales@ironmongerydirect.com
                            </a>{' '}
                            and our team will be happy to help.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`accordion-card ${
                        header.header4 ? 'border-2 border-gray-accordion rounded-t-3xl' : ''
                      }`}
                    >
                      <div
                        className={`accordion-card-header ${header.header4 ? 'rounded-t-2xl' : ''}`}
                        id="headingFour"
                      >
                        <h2>
                          <button
                            className="btn btn-link collapsed text-xl leading-snug h-auto p-4 pr-10 min-h-60px"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            onClick={() =>
                              setHeader({
                                header1: false,
                                header3: false,
                                header4: !header.header4,
                                header5: false,
                                header6: false,
                                header7: false,
                              })
                            }
                          >
                            What do I do if I have an outstanding back order?{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className={header.header4 ? 'collapsed' : ''}
                            >
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        className={`collapse ${header.header4 ? 'show' : ''}`}
                        aria-labelledby="headingFour"
                        data-parent="#accordionReturns"
                      >
                        <div className="accordion-card-body">
                          <p className="text-xl md:text-2xl leading-7">
                            Our team will be in touch with you to arrange next steps with your back
                            order.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`accordion-card ${
                        header.header5 ? 'border-2 border-gray-accordion rounded-t-3xl' : ''
                      }`}
                    >
                      <div
                        className={`accordion-card-header ${header.header5 ? 'rounded-t-2xl' : ''}`}
                        id="headingFive"
                      >
                        <h2>
                          <button
                            className="btn btn-link collapsed text-xl leading-snug h-auto p-4 pr-10 min-h-60px"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            onClick={() =>
                              setHeader({
                                header1: false,
                                header3: false,
                                header4: false,
                                header5: !header.header5,
                                header6: false,
                                header7: false,
                              })
                            }
                          >
                            Who do I contact about an order not delivered?{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className={header.header5 ? 'collapsed' : ''}
                            >
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFive"
                        className={`collapse ${header.header5 ? 'show' : ''}`}
                        aria-labelledby="headingFive"
                        data-parent="#accordionReturns"
                      >
                        <div className="accordion-card-body">
                          <p className="text-xl md:text-2xl leading-7">
                            If the expected delivery date has passed, please check you haven’t
                            received a notification (via SMS or email) from our couriers – they may
                            have missed you or have a delay in the network. If they haven’t
                            contacted you, you can get in touch with our team by email at{' '}
                            <a href="mailto:sales@ironmongerydirect.com">
                              sales@ironmongerydirect.com
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`accordion-card ${
                        header.header6 ? 'border-2 border-gray-accordion rounded-t-3xl' : ''
                      }`}
                    >
                      <div
                        className={`accordion-card-header ${header.header6 ? 'rounded-t-2xl' : ''}`}
                        id="headingSix"
                      >
                        <h2>
                          <button
                            className="btn btn-link collapsed text-xl leading-snug h-auto p-4 pr-10 min-h-60px"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                            onClick={() =>
                              setHeader({
                                header1: false,
                                header3: false,
                                header4: false,
                                header5: false,
                                header6: !header.header6,
                                header7: false,
                              })
                            }
                          >
                            What do I do about my trade account balance?{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className={header.header6 ? 'collapsed' : ''}
                            >
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseSix"
                        className={`collapse ${header.header6 ? 'show' : ''}`}
                        aria-labelledby="headingSix"
                        data-parent="#accordionReturns"
                      >
                        <div className="accordion-card-body">
                          <p className="text-xl md:text-2xl leading-7">
                            Our accounts team will shortly be contacting customers with an open
                            balance on their account. In the meantime, please continue to make
                            payments as usual.{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`accordion-card ${
                        header.header7 ? 'border-2 border-gray-accordion rounded-t-3xl' : ''
                      }`}
                    >
                      <div
                        className={`accordion-card-header ${header.header7 ? 'rounded-t-2xl' : ''}`}
                        id="headingSeven"
                      >
                        <h2>
                          <button
                            className="btn btn-link collapsed text-xl leading-tight  h-auto p-4"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                            onClick={() =>
                              setHeader({
                                header1: false,
                                header3: false,
                                header4: false,
                                header5: false,
                                header6: false,
                                header7: !header.header7,
                              })
                            }
                          >
                            How do I contact you?{' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className={header.header7 ? 'collapsed' : ''}
                            >
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseSeven"
                        className={`collapse ${header.header7 ? 'show' : ''}`}
                        aria-labelledby="headingSeven"
                        data-parent="#accordionReturns"
                      >
                        <div className="accordion-card-body">
                          <p className="text-xl md:text-2xl leading-7">
                            From 1st September onwards our phone lines will be operational on a
                            reduced schedule, with our agents being available Monday to Friday
                            between 7am until 6pm. We understand that you may have questions or need
                            assistance, and we still remain committed to providing support during
                            this time, so please do not hesitate to contact us on 0300 303 88 26 or
                            email us at{' '}
                            <a href="mailto:sales@ironmongerydirect.com">
                              sales@ironmongerydirect.com
                            </a>
                            .{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
      </main>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const errorCall: boolean[] = [];
  let campaignBanner: campaign[] = [];
  let companyFind: string[] = [];
  let trustPilotReviewOverview: TrustPilotOverview = null;
  // let homeBlogs: blogs['blogContents'] = [];
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const trustPilotApiKey = process.env.NEXT_TRUSTPILOT_API_KEY;
  const businessID =
    companyID === Company.Ironmongery ? '4bdd414c0000640005060f21' : '58ad71fa0000ff00059d2733';

  await axios({
    url: `${
      process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL
    }/cms/Campaigns/HomepageCampaigns?page=1&pageSize=20&orderByColumn=first_publication_date&orderBy=desc&documentType=campaign&uid=${
      Number(process.env.NEXT_PUBLIC_COMPANY_ID) === Company.Ironmongery
        ? 'headline-test'
        : 'google'
    }&companyID=${companyID}`,
    headers: {
      universe:
        Number(process.env.NEXT_PUBLIC_COMPANY_ID) === Company.Ironmongery
          ? 'ironmongery'
          : 'electrical',
      'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY,
    },
  })
    .then((response) => {
      campaignBanner = response.data;
      errorCall.push(false);
    })
    .catch(() => {
      // [JM] : Causes ED homepage to return 500 internal server error
      // errorCall.push(true);
    });

  await axios({
    url: `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/cms/Campaigns/SecondaryCampaigns?page=1&pageSize=20&companyID=${companyID}`,
    headers: {
      universe:
        Number(process.env.NEXT_PUBLIC_COMPANY_ID) === Company.Ironmongery
          ? 'ironmongery'
          : 'electrical',
      'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY,
    },
  })
    .then((response) => {
      companyFind = response.data;
      errorCall.push(false);
    })
    .catch(() => {
      // [JM] : Causes ED homepage to return 500 internal server error
      // errorCall.push(true);
    });

  await axios({
    method: 'GET',
    url: `https://api.trustpilot.com/v1/business-units/${businessID}`,
    headers: {
      apiKey: trustPilotApiKey,
    },
  }).then((response) => {
    trustPilotReviewOverview = response.data;
  });

  const trustPilotBusinessReviews = await axios({
    method: 'GET',
    url: `https://api.trustpilot.com/v1/business-units/${businessID}/reviews?stars=5&page=1&perPage=3`,
    headers: {
      apiKey: trustPilotApiKey,
    },
  }).then((response) => {
    const reviews: TrustPilotReview[] = response.data.reviews;
    const reviewWithProfilePic = reviews.map(async (review) => {
      let consumerProfile = null;
      await axios({
        method: 'GET',
        url: `https://api.trustpilot.com/v1/consumers/${review.consumer.id}/profile`,
        headers: {
          apiKey: trustPilotApiKey,
        },
      }).then((consumerResponse) => {
        consumerProfile = consumerResponse.data.profileImage.image73x73;
      });

      return {
        ...review,
        consumerProfile,
      };
    });

    const fullTrustPilotReview = Promise.all(reviewWithProfilePic).then((response) => {
      return response;
    });

    return fullTrustPilotReview;
  });

  // await axios({
  //   method: 'GET',
  //   url: `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/cms/Blogs/GetHomePageBlogs?companyID=${companyID}`,
  //   headers: {
  //     // Universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
  //     'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY,
  //   },
  // })
  //   .then((response) => {
  //     if (response.status === 200) {
  //       homeBlogs = response.data.slice(0, 3);
  //     } else {
  //       homeBlogs = [];
  //     }
  //   })
  //   .catch(() => {
  //     homeBlogs = [];
  //   });

  return {
    props: {
      error: errorCall.some((value) => value === true),
      campaignBanner: campaignBanner,
      companyFind: companyFind,
      trustPilotOverview: trustPilotReviewOverview,
      trustPilotReviews: trustPilotBusinessReviews,
      // homeBlogs,
    },
    revalidate: 86400,
  };
};

export default Home;
