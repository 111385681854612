import PageWrapper from '@components/pageWrapper';
import Company from 'types/company';
import Carousel from '@components/Carousel';
import Link from 'next/link';
import { GTagCategory } from 'types/googleAnalytics';

interface discoverProps {
  companyID: Company;
}

const Discover = ({ companyID }: discoverProps): JSX.Element => {
  const discoverCategoriesID = [
    {
      link: '/browse/new',
      imgUrl: 'https://ta-client-assets.s3.amazonaws.com/ironmongerydirect/1416/image2.svg',
      title: 'New Products',
    },
    // {
    //   link: '/browse/door-hardware-and-closers',
    //   imgUrl:
    //     'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/ironmongery/category-sections/2019/Door-Hardware-and-Closers.jpg',
    //   title: 'Door Hardware & Closers',
    // },
    {
      link: '/browse/locks-safety-and-security',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/ironmongery/category-sections/2019/Locks-Safety-and-Security.jpg',
      title: 'Locks, Safety & Security',
    },
    {
      link: '/browse/cabinet-and-shelf-hardware',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/ironmongery/category-sections/2019/Cabinet-and-Shelf-Hardware.jpg',
      title: 'Cabinet & Shelf Hardware',
    },
    {
      link: '/browse/window-furniture-and-hardware',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/ironmongery/category-sections/2019/Window-Furniture-and-Hardware.jpg',
      title: 'Window Furniture & Hardware',
    },
    {
      link: '/browse/hinges-seals-and-intumescent',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/ironmongery/category-sections/2019/Hinges-Seals-and-Intumescent.jpg',
      title: 'Hinges, Seals & Intumescent',
    },
  ];

  const discoverCategoriesED = [
    {
      link: '/browse/new',
      imgUrl: 'https://ta-client-assets.s3.amazonaws.com/ironmongerydirect/1416/image2.svg',
      title: 'New Products',
    },
    // {
    //   link: '/browse/sockets-and-wiring',
    //   imgUrl:
    //     'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/electrical/homepage/categories/2019/01/wiring-accessories.jpg',
    //   title: 'Sockets & Wiring',
    // },
    {
      link: '/browse/cables-and-management',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/electrical/homepage/categories/2019/01/cable-management.jpg',
      title: 'Cables & Management',
    },
    {
      link: '/browse/lighting',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/electrical/homepage/categories/2019/01/lamps-tubes.jpg',
      title: 'Lighting',
    },
    {
      link: '/browse/smart-home-and-ev-car-charging',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/electrical/homepage/categories/2019/01/smart-home.jpg',
      title: 'Smart Home',
    },
    {
      link: '/browse/consumer-units-and-circuit-protection',
      imgUrl:
        'https://img-cdn.manutantraders.com/image/upload/t_hp_discover,f_auto/electrical/homepage/categories/2019/01/consumer-units.jpg',
      title: 'Consumer Units',
    },
  ];

  const discoverCategories =
    companyID === Company.Ironmongery ? discoverCategoriesID : discoverCategoriesED;

  return (
    <section className="home-discover">
      <PageWrapper>
        <div className="home-discover__inner">
          <header className="home-discover__head">
            <div className="home-discover__head-inner">
              <h2>Discover our full range of products</h2>
              <div className="home-discover__head-actions hidden md:block">
                <Link href="/browse/all">
                  <a className={`cta-btn${companyID === Company.Ironmongery ? '--id' : '--ed'}`}>
                    <span>
                      {'View Full Range '}
                      <span className="md:hidden lg:inline-block"> of Products</span>
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          </header>
          <div className="home-discover__body">
            <Carousel
              companyID={companyID}
              draggable={true}
              direction={'x'}
              content={discoverCategories.map((category, index) => (
                <div
                  className="carousel__slide home-discover__slide"
                  key={`discover-category-${index}`}
                >
                  <div className="home-discover__card">
                    <div className="home-discover__card--inner">
                      <Link href={category.link}>
                        <a className="home-discover__card--link"> </a>
                      </Link>
                      <div className="home-discover__card--image">
                        <div
                          className="card__image--inner"
                          style={{
                            backgroundImage: `url('${category.imgUrl}')`,
                          }}
                        ></div>
                      </div>
                      <div className="home-discover__card--content">
                        <div className="card__content--inner">
                          <p>{category.title}</p>
                        </div>
                        <div className="card__content--actions">
                          <Link href={category.link}>
                            <a
                              role="button"
                              tabIndex={0}
                              className="cta-btn cta-btn--plain cta-btn--small"
                              onClick={() => {
                                ga(
                                  'send',
                                  'event',
                                  GTagCategory.Homepage,
                                  'Product Categories',
                                  category.title
                                );
                              }}
                            >
                              <span className="block text-center">View Products</span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            />
            <div className="section__actions hidden visible-xs-block">
              <Link href="/browse/all">
                <a className={`cta-btn${companyID === Company.Ironmongery ? '--id' : '--ed'}`}>
                  <span>View Full Range</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </PageWrapper>
    </section>
  );
};

export default Discover;
